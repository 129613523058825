






















































import { Component, Vue, Watch } from "vue-property-decorator";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Talk from "talkjs";
import Ads from "@/components/shared/Ads.vue";
import eventHub from "@/event-hub";
import { a11yFixBuefyNotificationAriaAttrs } from "@/services/a11y";

@Component({
    directives: {
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    },
    components: {
        "fmi-ads": Ads,
        PageTitleHeader
    }
})
export default class MessageCenterView extends Vue {
    talkInbox!: Talk.Inbox;
    personName = "";
    personCompany = "";

    get textHeader() {
        return this.$store.getters.getPageOptions("messageCenter").textHeader;
    }

    get talkModule() {
        return this.$store.state.talkVuexModule;
    }

    get talkSession() {
        return this.talkModule.talkSession;
    }

    get talkUser() {
        return this.talkModule.talkUser;
    }

    get userId() {
        return this.$store.getters.user.id;
    }

    get layoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get talkMeetingPrefix() {
        return this.$store.getters.talkPrefix;
    }

    get calloutText() {
        return this.$store.getters.getPageOptions("messageCenter").content
            ?.calloutText
            ? this.$store.getters.getPageOptions("messageCenter").content
                  .calloutText
            : "Want to start a conversation?";
    }

    get linkText() {
        return this.$store.getters.getPageOptions("messageCenter").content
            ?.linkText
            ? this.$store.getters.getPageOptions("messageCenter").content
                  .linkText
            : "Find someone in the Attendee Search";
    }

    @Watch("userId")
    setupInboxOnUserChange() {
        this.setupInbox();
    }

    @Watch("talkSession")
    setupInboxOnSessionChange() {
        this.setupInbox();
    }

    mounted() {
        this.setupInbox();
    }

    created() {
        eventHub.$on(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );
    }

    beforeDestroy() {
        eventHub.$off(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );
    }

    setupInbox() {
        if (this.talkUser && this.talkMeetingPrefix) {
            Talk.ready.then(() => {
                let conversation = null;

                if (this.$route.params.id && this.$route.params.name) {
                    const talkId = this.talkMeetingPrefix
                        ? `${this.talkMeetingPrefix}-${this.$route.params.id}`
                        : this.$route.params.id;

                    const otherUser = new Talk.User({
                        id: talkId,
                        name: this.$route.params.name,
                        role: `${this.talkMeetingPrefix}_attendee`,
                        custom: {
                            companyName: this.$route.params.companyName
                                ? this.$route.params.companyName
                                : ""
                        }
                    });

                    conversation = this.talkModule.talkSession.getOrCreateConversation(
                        Talk.oneOnOneId(this.talkModule.talkUser, otherUser)
                    );
                    conversation.setParticipant(this.talkModule.talkUser);
                    conversation.setParticipant(otherUser);

                    conversation.setAttributes({
                        custom: {
                            meetingPrefix: this.talkMeetingPrefix,
                            participant1Id: this.$route.params.id,
                            participant1Name: this.$route.params.name,
                            participant1CompanyName: this.$route.params
                                .companyName
                                ? this.$route.params.companyName
                                : "",
                            participant2Id: this.$store.getters.user.id,
                            participant2Name: this.$store.getters.user.name,
                            participant2CompanyName: this.$store.getters.user
                                .companyName
                                ? this.$store.getters.user.companyName
                                : ""
                        }
                    });

                    this.personName = this.$route.params.name;
                    this.personCompany = this.$route.params.companyName;
                }

                const talkContainer = document.getElementById(
                    "talkjs-container"
                );
                this.talkInbox = conversation
                    ? this.talkModule.talkSession.createInbox({
                          selected: conversation,
                          showFeedHeader: false
                      })
                    : this.talkModule.talkSession.createInbox({
                          showFeedHeader: false
                      });
                this.talkInbox.mount(talkContainer);

                this.talkInbox.on("conversationSelected", (e) => {
                    if (e.conversation !== null) {
                        this.personName =
                            e.conversation.custom.participant1Id ==
                            this.$store.getters.user.id
                                ? e.conversation.custom.participant2Name
                                : e.conversation.custom.participant1Name;
                        this.personCompany =
                            e.conversation.custom.participant1Id ==
                            this.$store.getters.user.id
                                ? e.conversation.custom.participant2CompanyName
                                : e.conversation.custom.participant1CompanyName;
                    }
                });
            });
        } else if (!this.talkMeetingPrefix) {
            console.log("Talk not initialized, no meeting prefix set");
        } else {
            console.log("Talk not initialized, not loading inbox");
        }
    }

    handleBuefyNotificationFixes(el: HTMLElement) {
        console.log("handle notification fixes");
        a11yFixBuefyNotificationAriaAttrs(el);
    }
}
