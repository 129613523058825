



















import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class Ads extends Vue {
    get publicPath() {
        return this.adSettings && this.adSettings.assetPath
            ? this.adSettings.assetPath
            : "Ads/";
    }

    get adSettings() {
        return this.$store.getters.getPageOptions("home").section.ads;
    }

    get ads() {
        return this.$store.getters.ads.filter((item: any) => {
            return item.isUsed != false;
        });
    }
}
